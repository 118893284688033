import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, List, ListItem, Avatar, Snackbar, Alert, IconButton, Modal, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../../utils/useDeviceInfo';

const avatars = [
  'https://data.heyraoul.fr/img/avatars/group/001.jpg',
  'https://data.heyraoul.fr/img/avatars/group/002.jpg',
  'https://data.heyraoul.fr/img/avatars/group/003.jpg',
  'https://data.heyraoul.fr/img/avatars/group/004.jpg',
  'https://data.heyraoul.fr/img/avatars/group/005.jpg',
  'https://data.heyraoul.fr/img/avatars/group/006.jpg',
];


const EditGroup = ({ roomId, onClose }) => {
  const { currentUser } = useAuth();
  const [roomDetails, setRoomDetails] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [groupAvatar, setGroupAvatar] = useState('');
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [friends, setFriends] = useState([]);
  const [confirmLeaveOpen, setConfirmLeaveOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [unknownUserOpen, setUnknownUserOpen] = useState(false);
  const [confirmReportOpen, setConfirmReportOpen] = useState(false);  
  const [reportSuccessOpen, setReportSuccessOpen] = useState(false);
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  
  useEffect(() => {
    if (currentUser && currentUser.friends) {
      setFriends(currentUser.friends);
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchRoomDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/${roomId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            // console.log("Room Details:", data);

            // Mettez à jour les détails de la salle
            setRoomDetails(data);

            // Mettez à jour le nom et l'avatar du groupe
            setGroupName(data.groupName || ''); // Valeur par défaut si null
            setGroupAvatar(data.groupAvatar || ''); // Valeur par défaut si null

            // Mettez à jour la liste des membres
            if (Array.isArray(data.userIds)) {
                setSelectedFriends(data.userIds);
            } else {
                console.warn("userIds n'est pas un tableau :", data.userIds);
                setSelectedFriends([]);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du cercle:', error);
        }
    };

    fetchRoomDetails();
}, [roomId]);


  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleAvatarChange = (avatar) => {
    setGroupAvatar(avatar);
    setAvatarModalOpen(false);
  };

  const handleUpdateGroup = async () => {
    if (!groupName) {
      setAlertOpen(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/updateRoomDetails`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          roomId,
          groupName,
          groupAvatar,
        }),
      });

      if (response.ok) {
        onClose();
      } else {
        console.error('Erreur lors de la mise à jour du cercle.');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du cercle:', error);
    }
  };

  const handleRemoveMember = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/removeMember`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          roomId,
          userId,
        }),
      });
  
      if (response.ok) {
        // Met à jour l'état pour retirer le membre supprimé
        setSelectedFriends((prevSelected) =>
          prevSelected.filter((friend) => friend._id !== userId)
        );
      } else {
        console.error('Erreur lors du retrait du membre.');
      }
    } catch (error) {
      console.error('Erreur lors du retrait du membre:', error);
    }
  };
  

  const handleAddMember = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/addMember`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          roomId,
          userId,
        }),
      });
  
      if (response.ok) {
        // Récupère les détails de l'ami ajouté
        const addedFriend = friends.find(friend => friend._id === userId);
        if (addedFriend) {
          setSelectedFriends((prevSelected) => [...prevSelected, addedFriend]);
        }
      } else {
        console.error("Erreur lors de l'ajout du membre.");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du membre :", error);
    }
  };
  

  const handleLeaveGroup = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/removeMember`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          roomId,
          userId: currentUser.userId,
        }),
      });

      if (response.ok) {
        navigate('/');
      } else {
        console.error('Erreur lors de la sortie du cercle.');
      }
    } catch (error) {
      console.error('Erreur lors de la sortie du cercle:', error);
    }
  };

  const handleDeleteGroup = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/deleteRoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          roomId,
          userId: currentUser.userId,
        }),
      });

      if (response.ok) {
        navigate('/');
      } else {
        console.error('Erreur lors de la suppression du cercle.');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du cercle:', error);
    }
  };

  const handleReportGroup = async () => {
    setConfirmReportOpen(false);

    const emailContent = `
      <h2>Signalement de la conversation</h2>
      <p><strong>Cercle :</strong> ${groupName}</p>
      <p><strong>ID du cercle :</strong> ${roomId}</p>
      <p><strong>ID de l'utilisateur :</strong> ${currentUser.userId}</p>
      <p><strong>Nom de l'utilisateur :</strong> ${currentUser.firstName} ${currentUser.lastName}</p>
    `;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/email/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject: 'Signalement de la conversation',
          html: emailContent,
        }),
      });

      if (response.ok) {
        setReportSuccessOpen(true);
      } else {
        console.error('Erreur lors de l\'envoi du signalement.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du signalement:', error);
    }
  };

  const handleBlockFriend = () => {
    navigate('/today', { state: { openFriendsDrawer: true } });
  };

  const filteredFriends = friends
  .filter((friend) =>
    friend.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (friend.lastName && friend.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
  )
  .filter((friend) => !selectedFriends.includes(friend._id));

  if (!roomDetails) {
    return <Typography>Chargement...</Typography>;
  }

  const isCreator = currentUser.userId === roomDetails.creator;

  return (
    <Box>
      {roomDetails.creator ? (
        isCreator ? (
          <>
            {/* Créateur - accès à tout sauf quitter */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, mt: 1 }}>
              <Avatar
                src={groupAvatar}
                sx={{ width: 70, height: 70, cursor: 'pointer', margin: '0 auto', border: '1px solid #DDD' }}
                onClick={() => setAvatarModalOpen(true)}
              />
            </Box>
            <TextField
              label="Nom du cercle"
              value={groupName}
              onChange={handleGroupNameChange}
              fullWidth
              sx={{ mb: 0 }}
            />
            <Button variant="contained" color="primary" onClick={handleUpdateGroup} fullWidth sx={{ mt: 1}}>
              Enregistrer
            </Button>
            <Typography variant="body1" fontWeight="bold" sx={{ mt: 2, mb:1.5 }}>
            Gérer les membres 
          </Typography>
            {/* Gérer les membres */}
            <TextField
              label="Ajouter un membre"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={{ mb: 2 }}
            />
            <List sx={{ p: 0 }}>
              {searchTerm && filteredFriends.map((friend) => (
                <ListItem sx={{ p: 0 }} key={friend._id} button onClick={() => handleAddMember(friend._id)}>
                  <IconButton sx={{ p: 0, mr: 1 }}><AddCircleRoundedIcon /></IconButton>
                  <Typography variant="body1">
                    {`${friend.firstName} ${friend.lastName || ''}`}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <List sx={{ p: 0, mt: 2 }}>
              {selectedFriends.map((user) => {
                return (
                  <ListItem key={user._id} sx={{ display: 'flex', alignItems: 'center', p: 0, mt: 1 }}>
                    <Avatar src={user.avatar || 'https://data.heyraoul.fr/img/avatars/001.jpg'} sx={{ border: '1px solid #DDD', mr: 1 }} />
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {user.firstName ? `${user.firstName} ${user.lastName || ''}` : 'Utilisateur inconnu'}
                    </Typography>
                    {isCreator && user._id !== currentUser.userId && (
                      <IconButton
                        edge="end"
                        color="error"
                        onClick={() => handleRemoveMember(user._id)}
                        sx={{ ml: 1 }}
                      >
                        <RemoveCircleRoundedIcon />
                      </IconButton>
                    )}
                  </ListItem>
                );
              })}
            </List>

            <Button variant="outlined" color="error" onClick={() => setConfirmReportOpen(true)} fullWidth sx={{ mt: 2 }}>
              Signaler la conversation
            </Button>

            <Button variant="text" color="error" onClick={() => setConfirmDeleteOpen(true)} fullWidth sx={{ mt: 1, mb: 2 }}>
              <HighlightOffRoundedIcon sx={{ mr: 1 }} /> Supprimer le cercle
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1" fontWeight="bold" sx={{ mt: 2 }}>
            Membres du cercle
          </Typography>
          <List sx={{ p: 0, mt: 1 }}>
              {selectedFriends.map((user) => {
                return (
                  <ListItem key={user._id} sx={{ display: 'flex', alignItems: 'center', p: 0, mt: 1 }}>
                    <Avatar src={user.avatar || 'https://data.heyraoul.fr/img/avatars/001.jpg'} sx={{ border: '1px solid #DDD', mr: 1 }} />
                    <Typography variant="body1" sx={{ flexGrow: 1, ml: 1 }}>
                      {user.firstName ? `${user.firstName} ${user.lastName || ''}` : 'Utilisateur inconnu'}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>

            {/* Non-créateur - peut signaler ou quitter */}
            <Button variant="contained" color="error" onClick={() => setConfirmReportOpen(true)} fullWidth sx={{ mt: 2 }}>
              Signaler la conversation
            </Button>
            <Button variant="outlined" onClick={handleLeaveGroup} fullWidth sx={{ mt: 1 }}>
              Quitter le cercle
            </Button>
          </>
        )
      ) : (
        <>
          {/* Pas de créateur */}
          <Button variant="contained" color="error" onClick={() => setConfirmReportOpen(true)} fullWidth sx={{ mt: 2 }}>
            Signaler la conversation
          </Button>
          <Button variant="outlined" onClick={handleBlockFriend} fullWidth sx={{ mt: 1 }}>
            Bloquer cet ami
          </Button>
        </>
      )}

      <Snackbar sx={{ pb: paddingBottom }} open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity="warning" sx={{ width: '100%' }}>
          Veuillez entrer un nom de cercle.
        </Alert>
      </Snackbar>

      {/* Dialogs */}
      {/* Quitter le cercle */}
      <Dialog open={confirmLeaveOpen} onClose={() => setConfirmLeaveOpen(false)}>
        <DialogTitle>Quitter le cercle</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir quitter ce cercle ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmLeaveOpen(false)}>Annuler</Button>
          <Button onClick={handleLeaveGroup} variant="contained" color="primary">Quitter</Button>
        </DialogActions>
      </Dialog>

      {/* Supprimer le cercle */}
      <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>Supprimer le cercle</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir supprimer ce cercle ? Cette action est irréversible.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Annuler</Button>
          <Button onClick={handleDeleteGroup} variant="contained" color="error">Supprimer</Button>
        </DialogActions>
      </Dialog>

      {/* Signaler la conversation */}
      <Dialog open={confirmReportOpen} onClose={() => setConfirmReportOpen(false)}>
        <DialogTitle>Signaler la conversation</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir signaler cette conversation ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmReportOpen(false)}>Annuler</Button>
          <Button onClick={handleReportGroup} variant="contained" color="error">Signaler</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={reportSuccessOpen} autoHideDuration={6000} onClose={() => setReportSuccessOpen(false)}>
        <Alert onClose={() => setReportSuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
          La conversation a été signalée avec succès.
        </Alert>
      </Snackbar>

      <Dialog open={avatarModalOpen} onClose={() => setAvatarModalOpen(false)}>
  <DialogTitle sx={{ textAlign:'center', mt:1 }} >Choisir un avatar</DialogTitle>
  <DialogContent sx={{ pb:0 }} >
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center', pb:0 }}>
      {avatars.map((avatar) => (
        <Avatar
          key={avatar}
          src={avatar}
          onClick={() => handleAvatarChange(avatar)}
          sx={{
            width: 70,
            height: 70,
            cursor: 'pointer',
            border: avatar === groupAvatar ? '1px solid #ddd' : '1px solid #ddd',
          }}
        />
      ))}
    </Box>
  </DialogContent>
  <DialogActions  sx={{ textAlign:'center', mb:1, mt:1 }} >
    <Button onClick={() => setAvatarModalOpen(false)} color="primary">
      Fermer
    </Button>
  </DialogActions>
</Dialog>



    </Box>
  );
};

export default EditGroup;
