import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Button,
} from '@mui/material';

const Reminder = ({ roomId, userId, onClose }) => {
  const [reminderTime, setReminderTime] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchReminder = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/rooms/${roomId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (!response.ok) {
          console.error('Erreur lors de la récupération du rappel:', response.status);
          return;
        }

        const data = await response.json();
        const userReminder = data.reminders?.find((reminder) => reminder.userId === userId);

        if (userReminder) {
          setReminderTime(userReminder.reminderTime);
          setIsEnabled(!!userReminder.reminderTime);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du rappel:', error);
      }
    };

    fetchReminder();
  }, [roomId, userId]);

  const handleTimeChange = (event) => {
    setReminderTime(event.target.value);
  };

  const handleToggleChange = (event) => {
    setIsEnabled(event.target.checked);
    if (!event.target.checked) setReminderTime('');
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/updateUserReminder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          roomId,
          userId,
          reminderTime: isEnabled ? reminderTime : null,
        }),
      });
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du rappel:', error);
    } finally {
      setIsSaving(false);
      if (onClose) onClose(); // Ferme le composant si une fonction de fermeture est fournie
    }
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 6; hour < 24; hour++) {
      const timeString = `${hour.toString().padStart(2, '0')}:00`;
      options.push(timeString);
    }
    return options;
  };

  return (
    <Box sx={{ px: 2, pt: 2, pb: 3 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" sx={{ mb: 3, p:2, backgroundColor:'secondary.main', borderRadius:2 }}>
        <Typography variant="h6">Activer le rendez-vous</Typography>
        <Switch checked={isEnabled} onChange={handleToggleChange} />
      </Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="reminder-time-label">Choisir l'heure</InputLabel>
        <Select
          labelId="reminder-time-label"
          value={reminderTime}
          onChange={handleTimeChange}
          label="Choisir l'heure"
          disabled={!isEnabled}
        >
          {generateTimeOptions().map((timeOption) => (
            <MenuItem key={timeOption} value={timeOption}>
              {timeOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSave}
        disabled={isSaving || (isEnabled && !reminderTime)}
      >
        {isSaving ? 'Enregistrement...' : 'Enregistrer'}
      </Button>
    </Box>
  );
};

export default Reminder;
