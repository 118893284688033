import React, { useState, useEffect } from 'react'; 
import { registerPlugin } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { useNavigate } from 'react-router-dom';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import io from 'socket.io-client'; 
import { App } from '@capacitor/app'; 
import { useAuth } from '../contexts/AuthContext';  

const socket = io(process.env.REACT_APP_BACKEND_URL);

const fcm = registerPlugin('FCM', {
  web: () => import('@capacitor-community/fcm').then(m => new m.FCM()),
});

export const registerForPushNotifications = async () => {
  try {
    const info = await Device.getInfo();

    // Gestion spécifique à iOS
    if (info.platform === 'ios') {
      // Demander explicitement l'autorisation des notifications sur iOS
      const permStatus = await PushNotifications.requestPermissions();
      if (permStatus.receive !== 'granted') {
        console.log("Autorisation de notifications non accordée sur iOS");
        await updateUserPushToken(null, false);
        return;  // Quitter si l'autorisation est refusée
      }
    }

    // Vérifier les permissions pour Android
    if (info.platform === 'android') {
      const permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive !== 'granted') {
        const requestResult = await PushNotifications.requestPermissions();
        if (requestResult.receive !== 'granted') {
          console.log("Permission non accordée pour Android.");
          await updateUserPushToken(null, false);
          return;
        }
      }

      // Créer un canal de notification si nécessaire pour Android
      await PushNotifications.createChannel({
        id: 'default_channel_id',
        name: 'Default Channel',
        description: 'Channel for general notifications',
        importance: 4,  // Importance: 4 = Default, 5 = High
        visibility: 1,  // 0 = Private, 1 = Public
        sound: 'default',
        vibration: true,
        lights: true,
        lightColor: '#FF0000',
      });
      console.log('Notification channel created');
    }

    // Enregistrer pour les notifications push sur les deux plateformes
    await PushNotifications.register();

    // Écouter les événements de registration
    PushNotifications.addListener('registration', async (token) => {
      console.log(`Token de registration: ${token.value}`);
      await updateUserPushToken(token.value, true);  // Envoyer le token au backend
    });

    // Gérer les erreurs de registration
    PushNotifications.addListener('registrationError', (error) => {
      console.error(`Erreur de registration: ${JSON.stringify(error)}`);
    });

    // Récupérer le token FCM (Firebase Cloud Messaging) pour Android et iOS
    const tokenData = await fcm.getToken();
    console.log('Token FCM:', tokenData.token);
    await updateUserPushToken(tokenData.token, true);  // Envoyer le token FCM au backend

  } catch (error) {
    console.error('Erreur lors de l\'enregistrement pour les notifications push:', error);
  }
};


export const PushNotificationHandler = () => {
  const navigate = useNavigate();
  const [isForeground, setIsForeground] = useState(true);
  const { currentUser } = useAuth();  // Récupérer l'utilisateur actuel via le contexte d'authentification

  useEffect(() => {
    // Gestion de l'état foreground/background de l'application
    const handleAppStateChange = ({ isActive }) => {
      setIsForeground(isActive);
    };

    App.addListener('appStateChange', handleAppStateChange);

    return () => {
      // App.removeListener('appStateChange', handleAppStateChange);
    };
  }, []);

  // Gestion des notifications push natives
  useEffect(() => {
    const listenerActionPerformed = PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      const notificationData = notification.notification.data;
      const url = notificationData?.url;

      if (url) {
        navigate(url);  // Naviguer vers l'URL contenue dans la notification native
      } else {
        console.error('URL manquante dans la notification native');
      }
    });

    return () => {
      // listenerActionPerformed.remove();
    };
  }, [navigate]);

  // Gestion des notifications locales
  useEffect(() => {
    const listenerLocalNotificationActionPerformed = LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      const notificationData = notification.notification.data;
      const url = notificationData?.url;

      if (url) {
        navigate(url);  // Naviguer vers l'URL contenue dans la notification locale
      } else {
        console.error('URL manquante dans la notification locale');
      }
    });

    return () => {
     // listenerLocalNotificationActionPerformed.remove();
    };
  }, [navigate]);

  useEffect(() => {
    const checkPlatformAndSetupNotifications = async () => {
      const info = await Device.getInfo();
      
      if (info.platform === 'android') {
        // Gestion des notifications locales via Socket.io uniquement quand l'app est en foreground
        if (isForeground && currentUser) {
          socket.on('localNotification', async (notification) => {
            console.log('Notification locale reçue via Socket.io:', notification);
  
            const senderId = notification.data.senderId;  // Récupérer l'ID de l'expéditeur
            const currentUserId = currentUser.userId;  // Récupérer l'ID de l'utilisateur actuel via AuthContext
  
            // Filtrer les notifications envoyées par l'expéditeur lui-même
            if (senderId === currentUserId) {
              console.log("Notification ignorée car envoyée par l'utilisateur lui-même.");
              return;
            }
  
            try {
              await LocalNotifications.schedule({
                notifications: [
                  {
                    title: notification.title,
                    body: notification.body,
                    id: Math.floor(Math.random() * 10000),  // Utilisation d'un entier simple pour l'ID
                    schedule: { at: new Date(Date.now() + 1000) },
                    sound: null,
                    attachments: null,
                    actionTypeId: '',
                    data: { url: notification.data.url },  // Ajout de l'URL dans les données
                    smallIcon: 'ic_notification',  // Utilisation de l'icône dans drawable-xxhdpi
                    channelId: 'default_channel_id',  // Utilisation du canal de notification configuré pour Android
                  },
                ],
              });
              console.log('Notification locale programmée avec succès.');
            } catch (error) {
              console.error('Erreur lors de la programmation de la notification locale:', error);
            }
          });
        }
      }
    };
  
    checkPlatformAndSetupNotifications();
  
    return () => {
      socket.off('localNotification');
    };
  }, [isForeground, currentUser]);
  

  return null;
};

export const pushNotificationReceived = () => {
  useEffect(() => {
    const listenerNotificationReceived = PushNotifications.addListener('pushNotificationReceived', (notification) => {
      const title = notification.title || 'Notification';
      const body = notification.body || 'Vous avez reçu une nouvelle notification';

      // alert(`${title}: ${body}`);
    });

    // Cleanup when the component is unmounted
    return () => {
     // listenerNotificationReceived.remove();
    };
  }, []);

  return null;
};

const updateUserPushToken = async (token, pushEnabled) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  try {
    const response = await fetch(`${backendUrl}/api/users/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ pushToken: token, pushEnabled }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message || 'Erreur lors de la mise à jour du token push');
    }

    const data = await response.json();
    // console.log('Token push mis à jour:', data);
  } catch (error) {
    console.error('Erreur lors de la mise à jour du token push:', error);
  }
};