import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StatusPage = () => {
  const { refreshToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Vérifier si le composant est monté pour éviter les effets secondaires

    const fetchSubscriptionStatus = async () => {
      try {
        // Simuler une progression de 2 secondes avant l'appel API
        setProgress(50); // Avancer la barre de progression
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Attente de 2 secondes

        if (!isMounted) return; // Si le composant est démonté, arrêter ici

        await refreshToken(); // Rafraîchir le token si nécessaire

        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/session`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const user = await response.json();
          // console.log('Statut de l\'abonnement:', user.subscriptionStatus);

          setProgress(100); // Compléter la barre de progression
          setTimeout(() => {
            if (user.subscriptionStatus === 'active') {
              navigate('/success'); // Redirection succès
            } else {
              navigate('/'); // Redirection échec
            }
            setLoading(false); // Fin du chargement
          }, 500);
        } else {
          console.error('Erreur API:', response.status);
          setProgress(100); // Compléter la barre
          setTimeout(() => {
            navigate('/'); // Redirection en cas d'erreur
            setLoading(false);
          }, 500);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'abonnement:', error);
        setProgress(100); // Compléter la barre
        setTimeout(() => {
          navigate('/'); // Redirection en cas d'erreur
          setLoading(false);
        }, 500);
      }
    };

    fetchSubscriptionStatus(); // Exécuter une seule fois au montage

    return () => {
      isMounted = false; // Empêcher toute action si le composant est démonté
    };
  }, [refreshToken, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      {loading && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Vérification de l'abonnement
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ width: '80%' }} />
        </>
      )}
    </Box>
  );
};

export default StatusPage;
