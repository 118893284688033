import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import Reply from '@mui/icons-material/Send';

const AIMessageInput = ({ onSendMessage, placeholder }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <Box sx={{ position: 'fixed', bottom: '-1px', width: '100%', backgroundColor: 'white', pb: 2, pt: 1.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 2, backgroundColor: '#eee', borderRadius: '20px', padding: '4px', pl: '10px' }}>
        <TextField
          variant="outlined"
          placeholder={placeholder}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px',
              backgroundColor: '#eee',
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
            '& .MuiOutlinedInput-input': {
              padding: '10px 12px',
            },
          }}
        />
        <IconButton
          onClick={handleSend}
          disabled={!message}
          sx={{
            marginLeft: '8px',
            color: 'black',
            backgroundColor: '#EEEEEE',
            '&:hover': {
              backgroundColor: '#DDDDDD',
            },
          }}
        >
          <Reply sx={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AIMessageInput;