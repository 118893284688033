import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; 
import { io } from 'socket.io-client'; 
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const avatarBasePath = '/avatar/';

const sanitizeAvatarPath = (path) => {
  if (!path) {
    return '/default-avatar.png'; // Retourne un chemin par défaut si le path est null ou undefined
  }
  if (path.startsWith('./')) {
    return path.replace('./', '/'); // Remplace "./" par "/"
  }
  return path;
};

const ActiveUsers = () => {
  const { roomCode } = useParams();
  const { currentUser } = useAuth();
  const [roomUsers, setRoomUsers] = useState([]); // Inclut les détails des utilisateurs
  const [readyUsers, setReadyUsers] = useState([]); // IDs des utilisateurs prêts
  const [onlineUsers, setOnlineUsers] = useState([]); // IDs des utilisateurs en ligne

  // Récupérer les utilisateurs de la room avec leurs détails
  useEffect(() => {
    const fetchRoomUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/${roomCode}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
        const roomData = await response.json();
        setRoomUsers(roomData.userIds); // userIds contient déjà les détails grâce au .populate
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs de la room:', error);
      }
    };

    fetchRoomUsers();
  }, [roomCode]);

  // Gérer les sockets pour les utilisateurs en ligne et prêts
  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL);

    socket.emit('joinRoom', { room: roomCode, token: localStorage.getItem('token') });

    socket.on('updateUserList', (userList) => {
      setOnlineUsers(userList.filter(user => !user.disconnected).map(user => user.userId));
    });

    socket.on('updateReadyUsers', (readyUserList) => {
      setReadyUsers(readyUserList.map(user => user.userId));
    });

    return () => {
      socket.disconnect();
    };
  }, [roomCode]);

  // Trier les utilisateurs pour afficher les en ligne en premier
  const sortedUsers = roomUsers.sort((a, b) => {
    const isAOnline = onlineUsers.includes(a._id);
    const isBOnline = onlineUsers.includes(b._id);

    if (isAOnline && !isBOnline) return -1;
    if (!isAOnline && isBOnline) return 1;
    return 0;
  });

  // Rendu du composant
  return (
    <Box
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box sx={{ display: 'inline-flex', p: 1 }}>
        {sortedUsers.map(user => {
          const isReady = readyUsers.includes(user._id);
          const isOnline = onlineUsers.includes(user._id);

          return (
            <Box key={user._id} sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', pt: 1, pb: 0.5, px: 1 }}>
              <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Avatar
                  src={sanitizeAvatarPath(user.avatar)}
                  sx={{ width: 40, height: 40, border: '2px solid black' }}
                />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    isReady ? (
                      <Box sx={{ bgcolor: 'white', borderRadius: '50%', width: 14, height: 14, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CheckCircleIcon color="primary" sx={{ width: 18, height: 18 }} />
                      </Box>
                    ) : isOnline ? (
                      <FiberManualRecordIcon sx={{ width: 18, height: 18, color: 'green' }} />
                    ) : (
                      <FiberManualRecordIcon sx={{ width: 18, height: 18, color: 'red' }} />
                    )
                  }
                  sx={{ position: 'absolute', top: '4px', right: '4px' }}
                />
              </Box>
              <Typography variant="body2" sx={{ textAlign: 'center', mt: 0.5, fontWeight: 'bold', fontSize: '0.8em' }}>
                {user.firstName || 'Inconnu'}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ActiveUsers;
