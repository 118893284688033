import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import AIChat from '../../components/ai/AIChat';
import useDeviceInfo from '../../utils/useDeviceInfo';

const AIChatPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { paddingTop } = useDeviceInfo();
  const [meetingId, setMeetingId] = useState(null); // État pour stocker l'ID du meeting
  const [sessionId, setSessionId] = useState(null); // État pour stocker l'ID de la session
  const isMeetingStarted = useRef(false); // Réf pour éviter le double démarrage du meeting
  const isUnmounting = useRef(false); // Réf pour suivre le démontage du composant

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Fonction pour démarrer un nouveau meeting
  const startMeeting = async () => {
    if (isMeetingStarted.current) return; // Ne pas démarrer si déjà en cours
    isMeetingStarted.current = true;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé. Veuillez vous reconnecter.');
      }

      console.log('Démarrage du meeting...'); // Log de débogage

      // Récupérer la session existante de l'utilisateur
      const sessionResponse = await fetch(`${backendUrl}/api/ai/start-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId: currentUser.userId,
          sessionType: 'chat',
        }),
      });

      if (!sessionResponse.ok) {
        throw new Error('Erreur lors de la récupération de la session.');
      }

      const sessionData = await sessionResponse.json();
      const sessionId = sessionData.sessionId;

      console.log('Session ID récupéré:', sessionId); // Log de débogage

      // Stocker le sessionId dans l'état
      setSessionId(sessionId);

      // Démarrer un nouveau meeting dans la session
      const meetingResponse = await fetch(`${backendUrl}/api/ai/start-meeting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sessionId,
        }),
      });

      if (!meetingResponse.ok) {
        throw new Error('Erreur lors du démarrage du meeting.');
      }

      const meetingData = await meetingResponse.json();
      console.log('Réponse du backend:', meetingData); // Log pour vérifier la réponse complète

      if (!meetingData.meetingId) {
        throw new Error('ID du meeting non reçu du backend.');
      }

      setMeetingId(meetingData.meetingId); // Stocker l'ID du meeting

      console.log('Meeting démarré avec ID:', meetingData.meetingId); // Log de débogage
    } catch (error) {
      console.error('Erreur lors du démarrage du meeting:', error.message);
      isMeetingStarted.current = false; // Réinitialiser en cas d'erreur
    }
  };

  // Fonction pour terminer le meeting
  const endMeeting = async () => {
    if (!meetingId || !sessionId) {
      console.log('Aucun meeting actif à terminer ou session ID manquant.'); // Log de débogage
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé. Veuillez vous reconnecter.');
      }

      console.log('Terminaison du meeting avec ID:', meetingId); // Log de débogage

      // Terminer le meeting
      const response = await fetch(`${backendUrl}/api/ai/end-meeting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sessionId,
          meetingId,
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la fin du meeting.');
      }

      console.log('Meeting terminé avec succès.'); // Log de débogage
    } catch (error) {
      console.error('Erreur lors de la fin du meeting:', error.message);
    } finally {
      setMeetingId(null); // Réinitialiser l'ID du meeting
      setSessionId(null); // Réinitialiser l'ID de la session
      isMeetingStarted.current = false; // Réinitialiser l'état du meeting
    }
  };

  // Fonction de nettoyage améliorée
  const safeEndMeeting = async () => {
    if (!isUnmounting.current && meetingId) {
      isUnmounting.current = true; // Marquer le début du démontage
      await endMeeting();
    }
  };

  // Gérer la navigation via le bouton retour
  const handleBackClick = async () => {
    await safeEndMeeting();
    navigate('/');
  };

  // Gérer le démontage du composant
  useEffect(() => {
    return () => {
      if (!isUnmounting.current) {
        safeEndMeeting(); // Gérer les fermetures de tab/rafraîchissements
      }
    };
  }, []);

  // Gérer les fermetures d'onglet ou de navigateur
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      safeEndMeeting();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Gérer la visibilité de la page (pour les PWA ou applications mobiles)
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        safeEndMeeting(); // Terminer le meeting si l'utilisateur quitte l'application
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  // Démarrer le meeting lorsque l'utilisateur arrive sur la page
  useEffect(() => {
    startMeeting();
  }, []); // Exécuter une seule fois au montage

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
      {/* En-tête de la page */}
      <Box sx={{ bgcolor: 'background.paper', borderBottom: '1px solid #DDD', display: 'flex', width: '100%', zIndex: '100', position: 'fixed', top: 0, alignItems: 'center', px: 2, pt: paddingTop + 2, pb: 2 }}>
        <IconButton sx={{ p: 0 }} aria-label="retour" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Avatar src="https://data.heyraoul.fr/img/avatars/user/004.jpg" sx={{ width: 36, height: 36, ml: 1.5, border: '1px solid #DDD' }} />
        <Typography variant="body" fontWeight="bold" sx={{ flexGrow: 1, ml: 1 }}>
          Raoul
        </Typography>
      </Box>

      {/* Composant de chat avec l'IA */}
      <Box sx={{ pt: paddingTop + 8 }}>
        <AIChat meetingId={meetingId} /> {/* Passer l'ID du meeting au composant AIChat */}
      </Box>
    </Box>
  );
};

export default AIChatPage;