// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Corrigé l'import de jwt-decode

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const signUp = async ({ firstName, lastName, email, password, phone, avatar }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${backendUrl}/api/users/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ firstName, lastName, email, password, phone, avatar }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Erreur lors de l\'inscription');
        }

        resolve();
      } catch (error) {
        console.error("Erreur lors de l'inscription :", error.message);
        reject(error);
      }
    });
  };

  const login = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${backendUrl}/api/users/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Problème de connexion');
        }
  
        const { token } = await response.json();
        localStorage.setItem('token', token);
  
        const decoded = jwtDecode(token);
        const user = {
          userId: decoded.userId,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          email: decoded.email,
          username: decoded.username, 
          phone: decoded.phone,
          avatar: decoded.avatar,
          role: decoded.role,
          subscriptionStatus: decoded.subscriptionStatus,
          subscriptionStartDate: decoded.subscriptionStartDate,
          onboarded: decoded.onboarded,
          experiencePoints: decoded.experiencePoints
        };
  
        setCurrentUser(user);
        resolve();
      } catch (error) {
        console.error("Erreur de connexion:", error.message);
        reject(error.message);
      }
    });
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      const response = await fetch(`${backendUrl}/api/users/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi du lien de réinitialisation.');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/users/logout`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Problème lors de la déconnexion');
      }

      localStorage.removeItem('token');
      setCurrentUser(null);

    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  const refreshUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const response = await fetch(`${backendUrl}/api/users/session`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const user = await response.json();
          setCurrentUser({
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            username: user.username,
            phone: user.phone,
            avatar: user.avatar,
            role: user.role,
            subscriptionStatus: user.subscriptionStatus,
            subscriptionStartDate: user.subscriptionStartDate,
            subscriptionProductId: user.subscriptionProductId,
            friendRequests: user.friendRequests, 
            friends: user.friends,
            meditationTime: user.meditationTime,
            onboarded: user.onboarded,
            experiencePoints: user.experiencePoints
          });
        } else {
          localStorage.removeItem('token');
          setCurrentUser(null);
        }
      } catch (error) {
        console.error("Erreur lors de la vérification de la session:", error);
        localStorage.removeItem('token');
        setCurrentUser(null);
      }
    } else {
      setCurrentUser(null);
    }
    setIsLoading(false);
  };
  
  const refreshToken = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(`${backendUrl}/api/users/refresh-token`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('token', data.token);
          const decoded = jwtDecode(data.token);
          const user = {
            userId: decoded.userId,
            firstName: decoded.firstName,
            lastName: decoded.lastName,
            username: decoded.username,
            email: decoded.email,
            avatar: decoded.avatar,
            role: decoded.role,
            subscriptionStatus: decoded.subscriptionStatus,
            subscriptionStartDate: decoded.subscriptionStartDate, 
            subscriptionProductId: decoded.subscriptionProductId, 
            experiencePoints: decoded.experiencePoints
          };
          setCurrentUser(user);
        } else {
          localStorage.removeItem('token');
          setCurrentUser(null);
        }
      } catch (error) {
        console.error("Erreur lors du rafraîchissement du token:", error);
        localStorage.removeItem('token');
        setCurrentUser(null);
      }
    }
  };

  const updateUser = async (updateData) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Utilisateur non authentifié');

    try {
      const response = await fetch(`${backendUrl}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour des informations de l\'utilisateur');
      }

      const updatedUser = await response.json();
      setCurrentUser(updatedUser);
    } catch (error) {
      console.error('Erreur lors de la mise à jour des informations de l\'utilisateur:', error);
    }
  };

  const sendFriendRequest = async (friendUsername) => {
    const token = localStorage.getItem('token');
    if (!token) return { success: false, message: 'Utilisateur non authentifié.' };
  
    try {
      const response = await fetch(`${backendUrl}/api/users/friend/invite/${friendUsername}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Lire les données de la réponse
      const data = await response.json();
  
      // Gérer la réponse manuellement
      if (!response.ok) {
        return { success: false, message: data.message || 'Erreur lors de l\'envoi de la demande d\'ami.' };
      }
  
      return { success: true, message: data.message || 'Demande d\'ami envoyée avec succès.' };
    } catch (error) {
      console.error('Erreur réseau ou inattendue:', error.message);
      return { success: false, message: 'Une erreur réseau est survenue.' };
    }
  };
  
  

  const acceptFriendRequest = async (friendId) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Utilisateur non authentifié');

    try {
      const response = await fetch(`${backendUrl}/api/users/friend/accept/${friendId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'acceptation de la demande d\'ami');
      }

      return await response.json();
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la demande d\'ami:', error);
      throw error;
    }
  };

  const rejectFriendRequest = async (friendId) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Utilisateur non authentifié');

    try {
      const response = await fetch(`${backendUrl}/api/users/friend/reject/${friendId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors du refus de la demande d\'ami');
      }

      return await response.json();
    } catch (error) {
      console.error('Erreur lors du refus de la demande d\'ami:', error);
      throw error;
    }
  };

  const removeFriend = async (friendId) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Utilisateur non authentifié');

    try {
      const response = await fetch(`${backendUrl}/api/users/friend/remove/${friendId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression de l\'ami');
      }

      return await response.json();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'ami:', error);
      throw error;
    }
  };

  useEffect(() => {
    const checkUserSession = async () => {
      setIsLoading(true);
      await refreshUser();
      setIsLoading(false);
    };

    checkUserSession();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isLoading, signUp, login, sendPasswordResetEmail, logout, refreshUser, refreshToken, updateUser, sendFriendRequest, acceptFriendRequest, rejectFriendRequest, removeFriend  }}>
      {children}
    </AuthContext.Provider>
  );
};
