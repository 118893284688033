import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Paper, Button, Avatar, Fade, AppBar, Toolbar, IconButton, Dialog, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../../utils/useDeviceInfo';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { registerForPushNotifications } from '../../utils/PushNotificationService';
import AddContact from '../../components/profile/friends/AddContact';
import { Share } from '@capacitor/share';
import VideoPlayer from '../../components/VideoPlayer'; 
import { useAuth } from '../../contexts/AuthContext';

const onboardingSteps = [
  {
    id: "welcome",
    sender: "Thibaut",
    message: "Bienvenue ! Je suis Thibaut, et je vais t'accompagner pour configurer ton espace de méditation 😎",
    responses: ["C'est parti !"]
  },
  {
    id: "benefits",
    sender: "Thibaut",
    message: "La méditation a plein de bienfaits : elle réduit le stress, améliore la concentration, et favorise le sommeil.",
    responses: ["D'accord"]
  },
  {
    id: "learn_techniques",
    sender: "Thibaut",
    message: "Pour bien commencer, je te conseille de pratiquer les 4 techniques de base : la prise de note, le scan corporel, la cohérence cardiaque et la visualisation ✨",
    responses: ["D'accord"]
  },
  {
    id: "meditation_time",
    sender: "Thibaut",
    message: "L’astuce, c’est de méditer un peu chaque jour pour vraiment en ressentir les bienfaits ! Alors, dis-moi, quel moment de la journée te semble le mieux pour toi ? 😊",
    responses: ["Le matin", "Le midi", "Le soir"]
  },
  {
    id: "enable_notifications",
    sender: "Thibaut",
    message: "Ok, c'est noté ! Pour recevoir des rappels de méditation, tu dois autoriser les notifications.",
    responses: ["J'active les notifications"]
  },
  {
    id: "add_friends",
    sender: "Thibaut",
    message: "Méditer, c'est encore mieux avec des amis ! Ajouter des amis sur l’app te permettra de partager tes progrès et de rester motivé 🙏",
    responses: ["J'ajoute des amis maintenant"]
  },
  {
    id: "invite_friends",
    sender: "Thibaut",
    message: "Tu n’as pas encore d’amis ici ? Pas de souci ! tu peux en inviter à te rejoindre, et vous pourrez méditer ensemble.",
    responses: ["OK, j'invite mes amis"]
  },
  {
    id: "finished",
    sender: "Thibaut",
    message: "Tout est prêt ! N'hésite pas à me contacter si tu as des questions. Bonne méditation ! 😁",
    responses: ["Merci, à toi aussi !"]
  }
];

const OnboardingChat = () => {
  const [step, setStep] = useState(0);
  const [conversation, setConversation] = useState([{ sender: "Thibaut", message: onboardingSteps[0].message }]);
  const [isTyping, setIsTyping] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openAddFriendsDrawer, setOpenAddFriendsDrawer] = useState(false);
  const [openBenefitsDialog, setOpenBenefitsDialog] = useState(false); // Pour la popin des bénéfices
  const [isVideoOpen, setIsVideoOpen] = useState(false); // Pour ouvrir la vidéo
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const { refreshUser } = useAuth();

  const updateUserData = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
  
      const responseData = await response.json();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données :", error);
    }
  };
  

  const handlePushNotifications = async () => {
    try {
      await registerForPushNotifications();
    } catch (error) {
      console.error("Erreur lors de l'activation des notifications:", error);
    }
  };

  const handleAddFriends = () => {
    setOpenAddFriendsDrawer(true); 
  };

  const handleAddFriendsClose = () => {
    setOpenAddFriendsDrawer(false); 
    proceedToNextStep();
  };

  const handleInviteFriends = async () => {
    try {
      await Share.share({
        title: 'Méditons ensemble',
        text: 'Rejoins-moi sur cette application de méditation pour méditer ensemble !',
        url: 'https://www.heyraoul.fr',
        dialogTitle: 'Inviter des amis'
      });
      proceedToNextStep();
    } catch (error) {
      console.error('Erreur lors de l\'invitation :', error);
      proceedToNextStep();
    }
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
    proceedToNextStep();
  };

  const calculateTypingDelay = (message) => {
    const wordsCount = message.split(" ").length;
    return Math.min(3000, wordsCount * 200);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEndClick = () => {
    navigate('/');
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation, isTyping]);

  const handleNextStep = (response) => {
    setButtonDisabled(true);
    
    setConversation((prev) => [...prev, { sender: "User", message: response }]);
    setIsTyping(true);
  
    const currentStepId = onboardingSteps[step].id;
  
    if (currentStepId === "meditation_time") {
      const selectedTime = response === "Le matin" ? "morning" : response === "Le midi" ? "noon" : "evening";
      updateUserData({ meditationTime: selectedTime });
    }
  
    if (currentStepId === "enable_notifications") {
      handlePushNotifications();
    }
  
    if (currentStepId === "add_friends") {
      handleAddFriends();
    }
  
    if (currentStepId === "invite_friends") {
      handleInviteFriends();
    }
  
    // Enlever toute condition pour l'étape "benefits" et s'assurer que proceedToNextStep est appelé
    if (currentStepId !== "add_friends" && currentStepId !== "invite_friends") {
      proceedToNextStep();
    }
  };
  

  const proceedToNextStep = () => {
    const nextStep = onboardingSteps[step + 1];
    const typingDelay = calculateTypingDelay(nextStep?.message || "");

    setTimeout(() => {
      setIsTyping(false);
      if (nextStep) {
        setConversation((prev) => [...prev, nextStep]);
        setStep(step + 1);
      }
      setButtonDisabled(false);
    }, typingDelay);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', px: 3, mt: 3, pt: paddingTop + 1  }}>
        
        <Box sx={{ width: '100%', maxWidth: 400, overflowY: 'auto', mb: 2, pb: 8 }}>
          {conversation.map((conv, index) => (
            <Fade in={true} key={index} timeout={{ enter: 800 }}>
              <Box sx={{ display: 'flex', mb: 2, justifyContent: conv.sender === "User" ? 'flex-end' : 'flex-start' }}>
                {conv.sender === "Thibaut" && (
                  <Avatar src="/img/thibaut.svg" sx={{ width: 44, height: 44, mr: 1.5, border: '1px solid #DDD' }} />
                )}
                <Paper elevation="0" sx={{ px: 2, py: 1.5, backgroundColor: conv.sender === "User" ? 'primary.main' : '#FFF', color: conv.sender === "User" ? '#FFF' : '#000', borderRadius: 4, border: conv.sender === "User" ? '1px solid #000' : '1px solid #DDD', }}>
                  <Typography variant="body2">{conv.message}</Typography>
                </Paper>
              </Box>
            </Fade>
          ))}

          {isTyping && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src="/img/thibaut.svg" sx={{ width: 44, height: 44, mr: 1.5, border: '1px solid #DDD' }} />
              <Typography variant="body2" sx={{ backgroundColor: '#FFF', borderRadius: '20px', px: 0, py: 1 }}>
                <span className="dot one">•</span><span className="dot two">•</span><span className="dot three">•</span>
              </Typography>
            </Box>
          )}

          <div ref={messagesEndRef} />
        </Box>

        {step < onboardingSteps.length - 1 && (
          <Box sx={{ position: 'fixed', bottom: '-1px', width: '100%', backgroundColor: 'white', border: '0px', pb: paddingBottom + 2, pt: 1.5, px: 3 }}>
            {onboardingSteps[step].responses.length === 3 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mx:-0.5 }}>
                {onboardingSteps[step].responses.map((response, idx) => (
                  <Button
                    key={idx}
                    sx={{ mx: 0.5 }}
                    variant="contained"
                    onClick={() => handleNextStep(response)}
                    disabled={buttonDisabled}
                  >
                    {response}
                  </Button>
                ))}
              </Box>
            )}

            {onboardingSteps[step].responses.length === 1 && (
              <Button
                variant="contained"
                onClick={() => handleNextStep(onboardingSteps[step].responses[0])}
                disabled={buttonDisabled}
              >
                {onboardingSteps[step].responses[0]}
              </Button>
            )}
          </Box>
        )}

        <Box sx={{ position: 'fixed', bottom: '-1px', width: '100%', pb: paddingBottom + 2, pt: 1.5, px: 3 }}>
        {step === onboardingSteps.length - 1 && (
            <Button variant="contained" onClick={() => {
            updateUserData({ onboarded: true })
                .then(() => refreshUser())  // Appelle refreshUser après avoir mis à jour les données
                .then(() => navigate('/')); // Puis navigue après avoir mis à jour les infos utilisateur
            }}>
            Terminer
            </Button>
        )}
        </Box>

        {/* Popin pour lancer la vidéo à la place des statistiques */}
        <VideoPlayer
          videoUrl="/video/test.mp4" // Remplacez avec l'URL de la vidéo souhaitée
          open={isVideoOpen}
          onClose={handleCloseVideo}
        />

        {/* Bottom Drawer pour ajouter des amis */}
        <Drawer
          anchor="bottom"
          open={openAddFriendsDrawer}
          onClose={handleAddFriendsClose}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>Ajouter mes amis</Typography>
            <AddContact />
          </Box>
        </Drawer>

        <React.Fragment>
          <style>
            {`
              .dot {
                animation: blink 1.5s infinite;
              }
              .dot.one {
                animation-delay: 0s;
              }
              .dot.two {
                animation-delay: 0.3s;
              }
              .dot.three {
                animation-delay: 0.6s;
              }

              @keyframes blink {
                0% { opacity: 0; }
                50% { opacity: 1; }
                100% { opacity: 0; }
              }
            `}
          </style>
        </React.Fragment>
      </Box>
    </>
  );
};

export default OnboardingChat;
