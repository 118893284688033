import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded'; // Import de l'icône d'alarme

const DuoStreak = ({ onNext, duoStreaks, currentSoloStreak }) => {
  
  // Limiter l'affichage à 3 duoStreaks maximum
  const limitedDuoStreaks = duoStreaks.slice(0, 3);

  return (
    <Box sx={{ backgroundColor: 'secondary.main', height: '100vh' }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="90vh"
        sx={{ px: 3 }} 
      >
        <Box component="img" src="/img/duo.svg" alt="Méditation" width="74vw" />
        
        {/* Texte principal */}
        <Typography variant="h4" sx={{ textAlign: "center", width: '70%' }} mt={2} mb={3} >
          Rendez-vous avec vos amis
        </Typography>

        {/* Boucle sur les duoStreaks, limitant à 3 */}
        {limitedDuoStreaks.map((duoStreak, index) => {
          // Formater le chemin de l'avatar pour enlever le "."
          const formattedAvatar = duoStreak.partnerAvatar ? duoStreak.partnerAvatar.replace('./', '/') : '/avatar/default-avatar.jpg';

          return (
            <Box 
              key={index}
              display="flex" 
              flexDirection="column" 
              justifyContent="center" 
              alignItems="center" 
              width="100%" 
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                bgcolor="#FFF" // Couleur de fond pour streak actuel
                borderRadius={2}
                p={2}
                mb={1}
              >
                <Box display="flex" alignItems="center">
                  <Avatar src={formattedAvatar} alt={duoStreak.duoPartner || 'Partenaire'} sx={{ width: 48, height: 48, border: '1px solid #DDD' }} />
                  <Box ml={2}>
                    <Typography sx={{ lineHeight:'1em', mb:1 }} variant="h6" color="textPrimary">
                      {duoStreak.duoPartner || 'Partenaire'}
                    </Typography>
                    
                    {/* Affichage du texte selon si le partenaire a médité ou non */}
                    {currentSoloStreak > duoStreak.currentDuoStreak ? (
                      <Typography sx={{ lineHeight:'1em' }} variant="body2" color="textSecondary">
                        N'a pas encore médité
                      </Typography>
                    ) : (
                      <Typography sx={{ lineHeight:'1em' }} variant="body2" color="textSecondary">
                        A médité aujourd'hui
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  {/* Si la personne n'a pas médité, affiche l'alarme, sinon la flamme */}
                  {currentSoloStreak > duoStreak.currentDuoStreak ? (
                    <WhatshotIcon color="primary" />
                  ) : (
                    <WhatshotIcon color="primary" />
                  )}
                  <Typography variant="h6" color="textPrimary" sx={{ ml: 0.5 }}>
                    {duoStreak.currentDuoStreak > 0 ? duoStreak.currentDuoStreak : '0'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DuoStreak;
