import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container, AppBar, Toolbar, IconButton, Drawer, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useAuth } from '../../contexts/AuthContext';
import RoomList from '../../components/room/RoomList';
import CreateGroup from '../../components/room/CreateGroup';
import useDeviceInfo from '../../utils/useDeviceInfo'; 

const AccessPage = () => {
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useAuth();
  const [roomCode, setRoomCode] = useState('');
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isGroupDrawerOpen, setIsGroupDrawerOpen] = useState(false); // État pour le Drawer du groupe
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleJoinRoom = () => {
    if (roomCode) {
      navigate(`/room/${roomCode}`);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGroupDrawerOpen = () => {
    setIsGroupDrawerOpen(true);
  };

  const handleGroupDrawerClose = () => {
    setIsGroupDrawerOpen(false);
  };

  return (
    <Box>
        <Container sx={{ p:0 }}>
          <RoomList /> 
          {/* Bouton flottant pour créer un groupe */}
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ position: 'fixed', bottom: 100, right: 30, boxShadow:'none' }}
            onClick={handleGroupDrawerOpen}
          >
            <AddIcon />
          </Fab>
        </Container>

      {/* Drawer pour créer un groupe */}
      <Drawer
        anchor="right"
        open={isGroupDrawerOpen}
        onClose={handleGroupDrawerClose}
        PaperProps={{ style: { width: '100%', height: '100%' } }}
      >
      <Box sx={{ px: 1, pt: paddingTop + 2 }}>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent', color: 'inherit' }}>
          <Toolbar>
          <IconButton sx={{ p: 0.5, border: '1px solid #ddd', borderRadius: '50%', }} edge="start" color="inherit" onClick={handleGroupDrawerClose} aria-label="close">
              <ArrowBackIosRoundedIcon sx={{ color:'grey' }} fontSize="small" />
            </IconButton>
          <Typography variant="h6" sx={{ textAlign:'left', flex: 1, ml:1 }}>
              Nouveau cercle
            </Typography>
          </Toolbar>
      </AppBar>
      </Box>
        <Box sx={{ px: 3 }}>
          <CreateGroup />
        </Box>
      </Drawer>
    </Box>
  );
};

export default AccessPage;
