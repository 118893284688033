import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Link, styled, TextField, Button, MobileStepper, Alert, Grid, Fade, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { formatEmail } from '../utils/emailUtils';
import useDeviceInfo from '../utils/useDeviceInfo';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const AlertContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  width: '90%',
  transform: 'translateX(-50%)',
  transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  opacity: 0,
  zIndex: 1000,
  '&.alert-visible': {
    transform: 'translateX(-50%) translateY(-20px)',
    opacity: 1,
  },
}));

const avatars = [
  'https://data.heyraoul.fr/img/avatars/user/001.jpg',
  'https://data.heyraoul.fr/img/avatars/user/002.jpg',
  'https://data.heyraoul.fr/img/avatars/user/003.jpg',
  'https://data.heyraoul.fr/img/avatars/user/004.jpg',
  'https://data.heyraoul.fr/img/avatars/user/005.jpg',
  'https://data.heyraoul.fr/img/avatars/user/006.jpg',
];

const questions = [
  { question: "Comment tu t'appelles ?", name: "firstName", type: "text" },
  { question: "Quel est ton nom de famille ?", name: "lastName", type: "text" },  // Nouvelle question ajoutée ici
  { question: "Quel est ton email ?", name: "email", type: "email" },
  { question: "Quel est ton numéro de téléphone ?", name: "phone", type: "tel" },
  { question: "Choisis ton avatar", name: "avatar", type: "avatar" },
  { question: "Crée un mot de passe", name: "password", type: "password" },
  { question: "Retape le mot de passe", name: "confirmPassword", type: "password" },
];

const SignUpPage = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [step, setStep] = useState(0);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',  // Ajout du champ lastName ici
    email: '',
    phone: '',
    countryCode: '+33',  // Par défaut, France
    password: '',
    confirmPassword: '',
    birthDate: '',
    avatar: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');

  // Fonction pour valider le format du mot de passe
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>/\\[\]]/.test(password);
    const isValid = password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;
  
    let helperText = '';
    if (!isValid) {
      helperText = 'Le mot de passe doit contenir :';
      if (password.length < minLength) helperText += ' au moins 8 caractères,';
      if (!hasUpperCase) helperText += ' une majuscule,';
      if (!hasNumber) helperText += ' un chiffre,';
      if (!hasSpecialChar) helperText += ' un caractère spécial,';
    }
  
    setPasswordHelperText(helperText);
    return isValid;
  };
  
  // Fonction pour formater le numéro de téléphone
  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(`${formData.countryCode}${phoneNumber}`);
    return phoneNumberObj ? phoneNumberObj.number.replace(/\s+/g, '') : phoneNumber;
  };

  // Fonction pour valider le formatage le numéro de téléphone
  const validatePhoneNumber = (phoneNumber, countryCode) => {
    const fullNumber = `${countryCode}${phoneNumber}`;
    const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
    
    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return phoneNumberObj.number.replace(/\s+/g, ''); // Retourne le numéro sans espaces
    } else {
      return false; // Numéro invalide
    }
  };

  // Fonction pour gérer le changement de l'indicatif
  const handleCountryCodeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      countryCode: e.target.value,
    }));
  };

  // Fonction pour gérer le changement du numéro de téléphone
  const handlePhoneChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: e.target.value,
    }));
  };

  // Fonction pour validation de chaque étape
  const validateStep = () => {
    let isValid = true;
    let errorMsg = '';
  
    if (step === 0 && !formData.firstName.trim()) {
      errorMsg = "Le prénom est requis.";
      isValid = false;
    }
  
    if (step === 1 && !formData.lastName.trim()) {
      errorMsg = "Le nom de famille est requis.";
      isValid = false;
    }
  
    if (step === 2) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.email)) {
        errorMsg = "L'email est invalide.";
        isValid = false;
      }
    }
  
    if (step === 3) {
      const validPhone = validatePhoneNumber(formData.phone, formData.countryCode);
      if (!validPhone) {
        errorMsg = "Le numéro de téléphone est invalide.";
        isValid = false;
      } else {
        formData.phone = validPhone;  // Remplacer par le numéro sans espaces
      }
    }
  
    if (step === 5 && !validatePassword(formData.password)) {
      errorMsg = "Le mot de passe ne respecte pas les critères.";
      isValid = false;
    }
  
    if (!isValid) {
      setErrorMessage(errorMsg);
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
    }
  
    return isValid;
  };
  
  const handleSignUp = async () => {
    // Valider le mot de passe
    if (!validatePassword(formData.password)) {
      setErrorMessage("Le mot de passe ne respecte pas les critères requis.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
      return;
    }
  
    // Comparaison des mots de passe
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
      return;
    }
  
    // Obtenir le numéro de téléphone sans espaces
    let phoneNumber = formData.phone.replace(/\s+/g, '');
  
    // Vérifier si le numéro commence déjà par l'indicatif
    if (!phoneNumber.startsWith(formData.countryCode)) {
      phoneNumber = `${formData.countryCode}${phoneNumber}`; // Ajouter l'indicatif si nécessaire
    }
  
    try {
      const formattedEmail = formatEmail(formData.email);
      
      // Envoie les données combinées
      await signUp({ ...formData, email: formattedEmail, phone: phoneNumber });
      navigate('/waiting');
    } catch (error) {
      if (error.message.includes('E11000')) {
        setErrorMessage("Un compte est déjà associé à cette adresse email.");
      } else {
        setErrorMessage("Erreur lors de l'inscription. Veuillez réessayer.");
      }
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
    }
  };  

  const handleBackClick = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/');
    }
  };

  const handleNextClick = () => {
    // Valider l'étape actuelle avant de passer à la suivante
    if (!validateStep()) {
      return;
    }
  
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      handleSignUp();
    }
  };  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') validatePassword(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleAvatarSelect = (avatar) => {
    setFormData({ ...formData, avatar: `${avatar}` });
  };

  return (
    <>
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 6 }}>
      <Box sx={{ width: '100%', maxWidth: 400, minHeight: 150, position: 'relative' }}>
        {questions.map((question, index) => (
          <Fade
            key={index}
            in={step === index}
            timeout={{ enter: 800, exit: 800 }}
            mountOnEnter
            unmountOnExit
          >
            <Box textAlign="center" sx={{ position: 'absolute', top: -40, left: 0, right: 0 }}>
              <Typography variant="h5">{question.question}</Typography>
              {question.name === 'avatar' ? (
                <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center' }}>
                  {avatars.map((avatar) => (
                    <Grid item xs={3} key={avatar}>
                      <img
                        src={`${avatar}`}
                        alt={avatar}
                        style={{
                          width: '100%',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          border: formData.avatar === `${avatar}` ? '6px solid #000' : '3px solid #000'
                        }}
                        onClick={() => handleAvatarSelect(avatar)}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : question.name === 'phone' ? (
                <>
                  <Box sx={{ display:'flex', mt:1 }}>
                    <TextField
                      select
                      label="Indicatif"
                      sx={{ mr:1 }}
                      value={formData.countryCode}
                      onChange={handleCountryCodeChange}
                      margin="normal"
                      SelectProps={{
                        native: true,
                      }}

                    >
                      <option value="+33">+33 (FR)</option>
                      <option value="+1">+1 (US)</option>
                      <option value="+44">+44 (GB)</option>
                      <option value="+49">+49 (DE)</option>
                      <option value="+39">+39 (IT)</option>
                      <option value="+34">+34 (ES)</option>
                      <option value="+81">+81 (JP)</option>
                      <option value="+86">+86 (CN)</option>
                      <option value="+91">+91 (IN)</option>
                      <option value="+61">+61 (AU)</option>
                      <option value="+7">+7 (RU)</option>
                      <option value="+55">+55 (BR)</option>
                      <option value="+82">+82 (KR)</option>
                      <option value="+31">+31 (NL)</option>
                      <option value="+1">+1 (CA)</option>
                      <option value="+46">+46 (SE)</option>
                      <option value="+32">+32 (BE)</option>
                      <option value="+41">+41 (CH)</option>
                      <option value="+47">+47 (NO)</option>
                      <option value="+45">+45 (DK)</option>
                      <option value="+358">+358 (FI)</option>
                      <option value="+52">+52 (MX)</option>
                      <option value="+27">+27 (ZA)</option>
                      <option value="+60">+60 (MY)</option>
                      <option value="+63">+63 (PH)</option>
                      <option value="+64">+64 (NZ)</option>
                      <option value="+65">+65 (SG)</option>
                      <option value="+66">+66 (TH)</option>
                      <option value="+90">+90 (TR)</option>
                      <option value="+94">+94 (LK)</option>
                      <option value="+254">+254 (KE)</option>
                      <option value="+256">+256 (UG)</option>
                      <option value="+30">+30 (GR)</option>
                      <option value="+36">+36 (HU)</option>
                      <option value="+48">+48 (PL)</option>
                      <option value="+51">+51 (PE)</option>
                      <option value="+54">+54 (AR)</option>
                      <option value="+56">+56 (CL)</option>
                      <option value="+62">+62 (ID)</option>
                      <option value="+66">+66 (TH)</option>
                      <option value="+92">+92 (PK)</option>
                      <option value="+93">+93 (AF)</option>
                      <option value="+353">+353 (IE)</option>
                      <option value="+354">+354 (IS)</option>
                      <option value="+355">+355 (AL)</option>
                      <option value="+356">+356 (MT)</option>
                      <option value="+372">+372 (EE)</option>
                      <option value="+373">+373 (MD)</option>
                      <option value="+374">+374 (AM)</option>
                      <option value="+375">+375 (BY)</option>
                      <option value="+380">+380 (UA)</option>
                    </TextField>

                    {/* Champ pour le numéro de téléphone sans l'indicatif */}
                    <TextField
                      fullWidth
                      label="Numéro de téléphone"
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      margin="normal"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{formData.countryCode}</InputAdornment>,
                      }}
                    />
                  </Box>
              </>
              ) : (
                <TextField
                  fullWidth
                  type={question.name === 'password' ? (showPassword ? 'text' : 'password') : (question.name === 'confirmPassword' ? (showConfirmPassword ? 'text' : 'password') : question.type)}
                  name={question.name}
                  value={formData[question.name]}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    endAdornment: (question.name === 'password' || question.name === 'confirmPassword') && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={question.name === 'password' ? handleClickShowPassword : handleClickShowConfirmPassword}
                        >
                          {(question.name === 'password' ? showPassword : showConfirmPassword) ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={question.name === 'password' && passwordHelperText ? passwordHelperText : ''}
                />
              )}
            </Box>
          </Fade>
        ))}
      </Box>
      <Box sx={{ position: 'absolute', pb: paddingBottom, bottom: 0, width: '100%', bgcolor: 'background.paper' }}>
        <Box display="flex" sx={{ px: 2, mb: 2 }} justifyContent="space-between">
          <Button onClick={handleBackClick}>Retour</Button>
          <Button variant="contained" onClick={handleNextClick}>{step < questions.length - 1 ? 'Suivant' : "S'inscrire"}</Button>
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Box sx={{ width: `${(step + 1) / questions.length * 100}%`, bgcolor: 'primary.main', height: 8, transition: 'width 0.5s' }} />
        </Box>
      </Box>
    </Box>
      <AlertContainer sx={{ pb: paddingBottom }} className={alertVisible ? 'alert-visible' : ''}>
        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </AlertContainer>
    </>
  );
};

export default SignUpPage;
