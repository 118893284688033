import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, Avatar, Typography, Paper } from '@mui/material';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';

const AIChatMessagesList = ({ messages, currentUser, messagesEndRef, isTyping }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <>
          {messages.length === 0 && (
            <Box sx={{ textAlign: 'center', px: 10, margin: 'auto auto', mt: 32 }}>
              <SettingsSuggestRoundedIcon sx={{ fontSize: 50, color: 'black', mb:0.5 }} />
              <Typography variant="h5" sx={{ mb: 0.5}}>
                Version bêta
              </Typography>
              <Typography variant="body1">
                Raoul est en période d'apprentissage.
              </Typography>
            </Box>
          )}

          <Box sx={{ pb: 9, px: 2 }}>
            <List sx={{ padding: 0 }}>
              {messages.map((msg, index) => {
                const isCurrentUser = msg.sender === currentUser.userId;
                const isAI = msg.sender === 'AI';

                return (
                  <React.Fragment key={index}>
                    {/* Ajout d'espace entre les groupes d'expéditeurs */}
                    {index > 0 && messages[index - 1].sender !== msg.sender && <Box sx={{ height: '20px' }} />}

                    {/* Message */}
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: isCurrentUser ? 'row-reverse' : 'row',
                        alignItems: 'flex-start',
                        padding: '0px 0',
                      }}
                    >
                      {/* Avatar pour l'IA */}
                      {isAI && (
                        <Avatar
                          src="https://data.heyraoul.fr/img/avatars/user/004.jpg"
                          sx={{ width: 32, height: 32, margin: '0 8px 0 0', border: '1px solid #DDD' }}
                        />
                      )}

                      {/* Bulle de message */}
                      <Paper
                        sx={{
                          boxShadow: 'none',
                          backgroundColor: isCurrentUser ? 'primary.main' : '#EEE', // Toujours gris pour l'IA
                          color: isCurrentUser ? '#FFF' : '#000',
                          borderRadius: '20px',
                          padding: '8px 16px',
                          position: 'relative',
                          marginBottom: '5px',
                          marginLeft: isCurrentUser ? '40px' : '0',
                          marginRight: isCurrentUser ? '0' : '40px',
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', maxWidth: '100%' }}>
                          <Typography variant="body2" color="inherit" component="span" sx={{ lineHeight: '1.4em', wordBreak: 'break-word' }}>
                            {msg.message}
                          </Typography>
                          <Typography sx={{ fontSize: '0.65rem', color: isCurrentUser ? '#FFF' : '#000', marginLeft: '8px' }}>
                            {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </Typography>
                        </Box>
                      </Paper>
                    </ListItem>
                  </React.Fragment>
                );
              })}

              {/* Afficher "..." pendant que l'IA rédige */}
              {isTyping && (
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: '0px 0',
                    marginTop:2,
                  }}
                >
                  <Avatar
                    src="https://data.heyraoul.fr/img/avatars/user/004.jpg"
                    sx={{ width: 32, height: 32, margin: '0 8px 0 0', border: '1px solid #DDD' }}
                  />
                  <Paper
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: '#EEE', // Couleur grise pour l'IA
                      color: '#000',
                      borderRadius: '20px',
                      padding: '8px 16px',
                      position: 'relative',
                      marginBottom: '5px',
                    }}
                  >
                    <Typography variant="body2" color="inherit" component="span" sx={{ lineHeight: '1.4em', wordBreak: 'break-word' }}>
                      <span className="dot one">•</span>
                      <span className="dot two">•</span>
                      <span className="dot three">•</span>
                    </Typography>
                  </Paper>
                </ListItem>
              )}

              <div ref={messagesEndRef} />
            </List>
          </Box>
        </>
      )}

      {/* Styles pour l'animation des points */}
      <style>
        {`
          .dot {
            animation: blink 1.5s infinite;
          }
          .dot.one {
            animation-delay: 0s;
          }
          .dot.two {
            animation-delay: 0.3s;
          }
          .dot.three {
            animation-delay: 0.6s;
          }

          @keyframes blink {
            0% { opacity: 0; }
            50% { opacity: 1; }
            100% { opacity: 0; }
          }
        `}
      </style>
    </>
  );
};

export default AIChatMessagesList;