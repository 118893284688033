import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, List, ListItem, Avatar, Snackbar, Alert, ToggleButton, ToggleButtonGroup, IconButton, Modal, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ListItemText from '@mui/material/ListItemText';
import useDeviceInfo from '../../utils/useDeviceInfo';

const avatars = [
  'https://data.heyraoul.fr/img/avatars/group/001.jpg',
  'https://data.heyraoul.fr/img/avatars/group/002.jpg',
  'https://data.heyraoul.fr/img/avatars/group/003.jpg',
  'https://data.heyraoul.fr/img/avatars/group/004.jpg',
  'https://data.heyraoul.fr/img/avatars/group/005.jpg',
  'https://data.heyraoul.fr/img/avatars/group/006.jpg',
];

const CreateGroup = () => {
  const { currentUser } = useAuth();
  const [friends, setFriends] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupAvatar, setGroupAvatar] = useState(avatars[0]);
  const [reminderTime, setReminderTime] = useState('19:00'); 
  const [step, setStep] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  useEffect(() => {
    if (currentUser && currentUser.friends) {
      setFriends(currentUser.friends);
    }
  }, [currentUser]);

  const handleFriendSelection = (friendId) => {
    setSelectedFriends((prevSelected) =>
      prevSelected.includes(friendId)
        ? prevSelected.filter((id) => id !== friendId)
        : [...prevSelected, friendId]
    );
  };

  const handleNextStep = () => {
    if (selectedFriends.length < 2) {
      setAlertOpen(true);
      return;
    }
    setStep(2);
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleAvatarChange = (avatar) => {
    setGroupAvatar(avatar);
    setAvatarModalOpen(false);
  };

  const handleReminderChange = (event) => {
    setReminderTime(event.target.value);
  };

  const handleCreateGroup = async () => {
    if (!groupName) {
      setAlertOpen(true);
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/createRoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          groupName,
          userIds: [currentUser.userId, ...selectedFriends],
          groupAvatar: `${groupAvatar}`,
          reminderTime, // Cela définit le même reminderTime pour tous les utilisateurs au départ
          creator: currentUser.userId, // Ajoute le créateur
        }),
      });
  
      if (response.ok) {
        window.location.reload(); // Actualise la page après la création du cercle
      } else {
        console.error('Erreur lors de la création du cercle.');
      }
    } catch (error) {
      console.error('Erreur lors de la création du cercle:', error);
    }
  };

  const filteredFriends = friends.filter((friend) =>
    friend.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (friend.lastName && friend.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 6; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');
      options.push(`${hour}:00`);
    }
    return options;
  };

  return (
    <Box>
      {step === 1 && (
        <>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 1, mt:1 }}>
            Sélectionner des amis
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              label="Rechercher des amis"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <List sx={{ pb:10, overflowY: 'auto' }}>
            {filteredFriends.map((friend) => (
              <ListItem key={friend._id} sx={{ p: 0, mt:0.8 }}>
                <ToggleButtonGroup
                  value={selectedFriends.includes(friend._id) ? [friend._id] : []}
                  exclusive
                  onChange={() => handleFriendSelection(friend._id)}
                  sx={{ flexGrow: 1, border:'0' }}
                >
                  <ToggleButton value={friend._id} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Avatar alt={friend.firstName} src={friend.avatar} sx={{ mr: 2, border: '1px solid #ddd' }} />
                    <ListItemText sx={{ textTransform:'none', textAlign:'left' }} primary={`${friend.firstName} ${friend.lastName || ''}`} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextStep}
            sx={{ position: 'fixed', bottom: 30, left: '50%', transform: 'translateX(-50%)', width:'fit-content' }}
          >
            Suivant
          </Button>
        </>
      )}

      {step === 2 && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt:1 }}>
            <Avatar
              src={`${groupAvatar}`}
              sx={{ width: 70, height: 70, cursor: 'pointer', margin:'0 auto', border:'1px solid #DDD' }}
              onClick={() => setAvatarModalOpen(true)}
            />
          </Box>
          <Modal
            open={avatarModalOpen}
            onClose={() => setAvatarModalOpen(false)}
            aria-labelledby="avatar-selection-modal"
            aria-describedby="modal-to-select-avatar"
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', p: 3 }}>
              <Box sx={{ backgroundColor: 'white', py: 4, px: 2, borderRadius: 2, textAlign: 'center' }}>
                <Typography variant="body" fontWeight="bold">Sélectionner un avatar</Typography>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', flexWrap: 'wrap', borderRadius: 0 }}>
                  {avatars.map((avatar) => (
                    <IconButton key={avatar} onClick={() => handleAvatarChange(avatar)}>
                      <Avatar src={`${avatar}`} sx={{ width: 64, height: 64, border:'1px solid #DDD' }} />
                    </IconButton>
                  ))}
                </Box>
              </Box>
            </Box>
          </Modal>
          <TextField
            label="Nom du cercle"
            value={groupName}
            onChange={handleGroupNameChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
            Programmer un rendez-vous
          </Typography>
          <FormControl component="fieldset" fullWidth>
            <InputLabel id="reminder-time-label">Choisir l'heure</InputLabel>
            <Select
              labelId="reminder-time-label"
              value={reminderTime}
              onChange={handleReminderChange}
              label="Choisir l'heure"
              fullWidth
            >
              {generateTimeOptions().map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGroup}
            sx={{ position: 'fixed', bottom: 30, left: '50%', transform: 'translateX(-50%)', width:'fit-content' }}
          >
            Créer le cercle
          </Button>
        </>
      )}

      <Snackbar sx={{ pb: paddingBottom }} open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity="warning" sx={{ width: '100%' }}>
          {selectedFriends.length < 2
            ? 'Veuillez sélectionner au moins deux amis.'
            : 'Veuillez entrer un nom de cercle.'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateGroup;
