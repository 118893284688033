import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import { Share } from '@capacitor/share';
import { useAuth } from '../../contexts/AuthContext';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ManageInvitations = () => {
  const { currentUser } = useAuth();
  const [state, setState] = useState({
    loading: true,
    guest: null,
    inviter: null,
    error: '',
  });
  const [success, setSuccess] = useState('');
  const [invitationLink, setInvitationLink] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ open: false, onConfirm: null, title: '', message: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
  
        const { subscriptionProductId } = currentUser;
  
        if (['classic', 'classic:classic'].includes(subscriptionProductId)) {
          const guestResponse = await fetch(`${backendUrl}/api/invitations/guest`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
  
          if (guestResponse.ok) {
            const guestData = await guestResponse.json();
            if (guestData.guest) {
              setState((prev) => ({ ...prev, guest: guestData.guest, loading: false }));
            } else {
              setState((prev) => ({ ...prev, guest: null, loading: false }));
            }
          } else if (guestResponse.status === 404) {
            // Cas attendu où il n'y a pas encore de guest
            console.warn('Aucun guest trouvé (404).');
            setState((prev) => ({ ...prev, guest: null, loading: false }));
          } else {
            throw new Error('Erreur inconnue lors de la récupération du guest.');
          }
        } else if (subscriptionProductId === 'guest') {
          const inviterResponse = await fetch(`${backendUrl}/api/invitations/inviter`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
  
          if (inviterResponse.ok) {
            const inviterData = await inviterResponse.json();
            if (inviterData.inviter) {
              setState((prev) => ({ ...prev, inviter: inviterData.inviter, loading: false }));
            } else {
              setState((prev) => ({ ...prev, inviter: null, loading: false }));
            }
          } else if (inviterResponse.status === 404) {
            // Cas attendu où il n'y a pas encore d'inviter
            console.warn('Aucun inviter trouvé (404).');
            setState((prev) => ({ ...prev, inviter: null, loading: false }));
          } else {
            throw new Error('Erreur inconnue lors de la récupération de l’inviter.');
          }
        } else {
          setState((prev) => ({ ...prev, loading: false }));
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error.message);
        setState((prev) => ({
          ...prev,
          error: 'Erreur de chargement. Veuillez réessayer plus tard.',
          loading: false,
        }));
      }
    };
  
    fetchData();
  }, [currentUser.subscriptionProductId]);
  

  const handleCreateInvitation = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/invitations/invite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ inviterId: currentUser.userId }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors de la création de l\'invitation.');
      }

      const link = `${window.location.origin}/accept-invitation/${data.invitation.token}`;
      setInvitationLink(link);
      setIsDialogOpen(true);
    } catch (error) {
      setState((prev) => ({ ...prev, error: 'Impossible de créer une invitation.' }));
    }
  };

  const handleRevokeAccess = () => {
    setConfirmationDialog({
      open: true,
      title: 'Changer de duo',
      message: 'Révoquer l\'accès à ton ami ?',
      onConfirm: async () => {
        try {
          if (!state.guest || !state.guest.token) {
            throw new Error('Aucune invitation à révoquer.');
          }
          const response = await fetch(`${backendUrl}/api/invitations/revoke/${state.guest.token}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (!response.ok) {
            throw new Error('Erreur lors de la révocation de l\'invitation.');
          }

          setSuccess('Abonnement de ton duo révoqué.');
          setState((prev) => ({ ...prev, guest: null }));
          handleCloseConfirmationDialog();
        } catch (error) {
          setState((prev) => ({ ...prev, error: error.message || 'Impossible de révoquer l\'invitation.' }));
          handleCloseConfirmationDialog();
        }
      },
    });
  };

  const handleLeaveDuo = () => {
    setConfirmationDialog({
      open: true,
      title: 'Quitter le duo',
      message: 'Êtes-vous sûr de vouloir quitter le duo ?',
      onConfirm: async () => {
        try {
          const response = await fetch(`${backendUrl}/api/invitations/leave`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (!response.ok) {
            throw new Error('Erreur lors du départ du duo.');
          }

          setSuccess('Vous avez quitté le duo avec succès.');
          setState((prev) => ({ ...prev, inviter: null }));
          handleCloseConfirmationDialog();
        } catch (error) {
          setState((prev) => ({ ...prev, error: error.message || 'Impossible de quitter le duo.' }));
          handleCloseConfirmationDialog();
        }
      },
    });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog((prev) => ({ ...prev, open: false }));
  };

  if (state.loading) return <CircularProgress />;

  return (
    <>
      {state.error && (
        <Alert severity="error" onClose={() => setState((prev) => ({ ...prev, error: '' }))}>
          {state.error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, border: '1px solid #DDD', borderRadius: 2, p: 1, width:'100%' }}>

    {['classic', 'classic:classic'].includes(currentUser.subscriptionProductId) && (
  state.guest ? (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={state.guest.avatar} alt={state.guest.firstName} sx={{ border: '1px solid #DDD', mr: 2 }} />
        <Typography sx={{ display: 'inline', mr: 0.5 }}>{state.guest.firstName}</Typography>
        <Typography sx={{ display: 'inline' }}>{state.guest.lastName}</Typography>
      </Box>
      <Button
        onClick={handleRevokeAccess}
        color="secondary"
        variant="contained"
        sx={{ ml: 2, width: 'fit-content', float: 'right' }}
      >
        Révoquer
      </Button>
    </Box>
  ) : (
    <Box
      onClick={handleCreateInvitation}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <Box
        sx={{
          border: '2px dashed black',
          borderRadius: '50%',
          width: 42,
          height: 42,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'grey',
          mr: 1.5,
        }}
      >
        <PersonAddAltRoundedIcon sx={{ color: 'black' }} />
      </Box>
      <Typography sx={{ fontWeight: 'bold' }}>Ajouter un duo</Typography>
    </Box>
  )
)}


      {currentUser.subscriptionProductId === 'guest' && state.inviter && (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={state.inviter.avatar} alt={state.inviter.firstName} sx={{ border: '1px solid #DDD', mr: 2 }} />
            <Typography sx={{ display: 'inline', mr: 0.5 }}>{state.inviter.firstName}</Typography>
            <Typography sx={{ display: 'inline' }}>{state.inviter.lastName}</Typography>
          </Box>
          <Button onClick={handleLeaveDuo} color="secondary" variant="contained" sx={{ ml: 2, width: 'fit-content', float: 'right' }}>
            Quitter le duo
          </Button>
        </Box>
      )}

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ pb:1 }} >Partage ton lien d'invitation</DialogTitle>
        <DialogContent sx={{ py:0 }} onClick={() => Share.share({
            title: 'Invitation à rejoindre l\'abonnement',
            text: 'Rejoignez mon abonnement grâce à ce lien :',
            url: invitationLink,
          })}>
          <Typography sx={{ backgroundColor:'secondary.main', p:2, borderRadius:2 }} >{invitationLink}</Typography>
        </DialogContent>
        <DialogActions sx={{ p:2 }}>
          <Button variant='contained' onClick={() => Share.share({
            title: 'Invitation à rejoindre l\'abonnement',
            text: 'Rejoignez mon abonnement grâce à ce lien :',
            url: invitationLink,
          })}>Partager</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationDialog.open} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{confirmationDialog.title}</DialogTitle>
        <DialogContent sx={{ py:0 }} >
          <Typography>{confirmationDialog.message}</Typography>
        </DialogContent>
        <DialogActions sx={{ p:2 }}>
        <Button variant='contained' onClick={() => { confirmationDialog.onConfirm(); handleCloseConfirmationDialog(); }} color="primary">
            Confirmer
          </Button>
          <Button onClick={handleCloseConfirmationDialog} >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </>
  );
};

export default ManageInvitations;