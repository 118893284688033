import React, { useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

const Badge = ({ onNext, newlyUnlockedBadges }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  // Gestion des mouvements de glissement
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const delta = touchStartX.current - touchEndX.current;

    if (delta > 50 && currentSlide < newlyUnlockedBadges.length - 1) {
      // Glissement vers la gauche
      setCurrentSlide((prev) => prev + 1);
    } else if (delta < -50 && currentSlide > 0) {
      // Glissement vers la droite
      setCurrentSlide((prev) => prev - 1);
    }
  };

  return (
    <Box
      sx={{ backgroundColor: 'secondary.main', height: '100vh', overflow: 'hidden', position: 'relative', paddingTop: '20vh' }}
    >
      {/* Conteneur des slides */}
      <Box
        display="flex"
        flexDirection="row"
        width={`${newlyUnlockedBadges.length * 100}vw`}
        sx={{
          transform: `translateX(-${currentSlide * 100}vw)`,
          transition: 'transform 0.5s ease-in-out',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {newlyUnlockedBadges.map((badge, index) => (
          <Box
            key={badge._id}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{
              minWidth: '100vw',
              px: 3,
            }}
          >
            <Box component="img" src={badge.imageUrl || '/img/badges/default-badge.svg'} alt={badge.title} width="50vw" />
            <Typography variant="body1" textTransform="uppercase" color="primary" mb={1} mt={3}>
              Badge débloqué
            </Typography>
            <Typography variant="h5" color="primary">
              {badge.description}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Dots indicateurs */}
      {newlyUnlockedBadges.length > 1 && (
        <Box
          sx={{
            mt: 10,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          {newlyUnlockedBadges.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: currentSlide === index ? 'primary.main' : 'grey.500',
                transition: 'background-color 0.3s ease',
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Badge;
