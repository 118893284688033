// TechniqueListPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, IconButton, AppBar, Toolbar } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import MeditationListComponent from '../components/MeditationListComponent';
import useDeviceInfo from '../utils/useDeviceInfo';

const TechniqueListPage = () => {
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleBackClick = () => {
    navigate('/temple');
  };

  return (
    <Box sx={{ pt: paddingTop + 1 }} >

      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar sx={{ px:2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 1,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
          <Typography color="black" sx={{ lineHeight:'1em' }} variant="h5">Méditations</Typography>
        </Toolbar>
      </AppBar>
    
      <Container sx={{ px: 3 }}>
        <Box sx={{ pb: 2 }}>

          <Box sx={{ border:"1px solid #DDD", borderRadius:2, display:"flex", alignItems: "center", justifyContent: "center", flexDirection:"column", p:1, mb:2 }}>
            <Typography variant="body2" color="textSecondary" fontWeight="bold"  sx={{ textAlign:'center', display: "flex", flexDirection: "row", alignItems: "center" }}>
            <EventAvailableRoundedIcon sx={{ fontSize: 18 }} />
            <Box sx={{ ml:0.5 }} >Une nouvelle méditation chaque semaine</Box>
            </Typography>
          </Box>

          <MeditationListComponent />
        </Box>
      </Container>
    </Box>
  );
};

export default TechniqueListPage;
