// src/components/onboarding/FinalSlide.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Invitations from './Invitations';

const FinalSlide = () => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center', padding: 3 }}>

      <Box component="img" src="/img/duo.svg" alt="Invitation Duo" width="60vw" />

      <Box>
        <Invitations/>
      </Box>
    </Box>
  );
};

export default FinalSlide;
