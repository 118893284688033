import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import AIChatMessagesList from './AIChatMessagesList';
import AIMessageInput from './AIMessageInput';
import ZenSpinner from '../ZenSpinner';

const AIChat = ({ meetingId }) => {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchSessionAndMessages = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé. Veuillez vous reconnecter.');
        }

        // Récupérer la session existante de l'utilisateur
        const sessionResponse = await fetch(`${backendUrl}/api/ai/start-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser.userId,
            sessionType: 'chat',
          }),
        });

        if (!sessionResponse.ok) {
          throw new Error('Erreur lors de la récupération de la session.');
        }

        const sessionData = await sessionResponse.json();
        setSessionId(sessionData.sessionId);

        // Charger l'historique des messages de la session
        const historyResponse = await fetch(`${backendUrl}/api/ai/session-history/${sessionData.sessionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!historyResponse.ok) {
          throw new Error('Erreur lors de la récupération de l\'historique des messages.');
        }

        const historyData = await historyResponse.json();

        // Normaliser les messages et convertir les timestamps en objets Date
        const normalizedMessages = historyData.flatMap(meeting =>
          meeting.messages.map(msg => ({
            ...msg,
            sender: msg.sender === 'user' ? currentUser.userId : 'AI',
            timestamp: new Date(msg.timestamp),
          }))
        );
        

        // Trier les messages par timestamp
        const sortedMessages = normalizedMessages.sort((a, b) => a.timestamp - b.timestamp);
        setMessages(sortedMessages);
      } catch (error) {
        console.error('Erreur lors du chargement de la session et de l\'historique:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSessionAndMessages();
  }, [currentUser.userId, backendUrl]);

  const sendMessage = async (message) => {
    if (!message.trim()) return;

    // Ajouter le message de l'utilisateur à la liste des messages
    const userMessage = { sender: currentUser.userId, message, timestamp: new Date() };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé. Veuillez vous reconnecter.');
      }

      // Simuler l'IA en train de rédiger
      setIsTyping(true);

      // Simuler un délai avant la réponse de l'IA
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Envoyer le message au backend
      const response = await fetch(`${backendUrl}/api/ai/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sessionId,
          meetingId,
          message,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la communication avec l\'IA');
      }

      const data = await response.json();
      const aiResponse = data.message;

      // Ajouter la réponse de l'IA à la liste des messages
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'AI', message: aiResponse, timestamp: new Date() },
      ]);
    } catch (error) {
      console.error('Erreur lors de la communication avec l\'IA:', error.message);
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isLoading]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ZenSpinner />
        </Box>
      )}

      {/* Liste des messages */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 9, pt: 3 }}>
        <AIChatMessagesList
          messages={messages}
          currentUser={currentUser}
          messagesEndRef={messagesEndRef}
          isTyping={isTyping}
        />
      </Box>

      {/* Input pour envoyer des messages */}
      <AIMessageInput
        onSendMessage={sendMessage}
        placeholder="Écrivez un message..."
        disabled={isCreatingSession || isTyping}
      />
    </Box>
  );
};

export default AIChat;