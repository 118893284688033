import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, List, ListItem, Snackbar, Alert } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { useAuth } from '../../../contexts/AuthContext';

const ManageAppointments = () => {
  const { currentUser } = useAuth();
  const [friends, setFriends] = useState([]);
  const [activeDuoStreaks, setActiveDuoStreaks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.friends) {
      fetchActiveDuoStreaks();
    }
  }, [currentUser]);


  const fetchActiveDuoStreaks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/duo-streaks/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      setActiveDuoStreaks(data);

      const availableFriends = currentUser.friends.filter(friend => {
        return !data.some(streak =>
          (streak.user1Id === currentUser.userId && streak.user2Id === friend._id) ||
          (streak.user2Id === currentUser.userId && streak.user1Id === friend._id)
        );
      });

      setFriends(availableFriends.slice(0, 3)); // Limiter à 3 amis
    } catch (error) {
      console.error('Erreur lors de la récupération des streaks actifs :', error);
    }
  };

  const handleStartAppointment = async (friendId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/duo-streaks/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ user1Id: currentUser.userId, user2Id: friendId }),
      });

      console.log('Alert state before open:', alertOpen);

      if (response.ok) {
       // console.log('Rendez-vous démarré avec succès !');
        fetchActiveDuoStreaks(); // Rafraîchir la liste des amis disponibles
      } else {
        console.error('Échec lors du démarrage du rendez-vous');
      }
    } catch (error) {
      console.error('Erreur lors du démarrage du rendez-vous :', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box sx={{ backgroundColor: 'secondary.main', height: '100vh', p: 4 }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="90vh">
          <PersonAddAltRoundedIcon sx={{ fontSize:"3em", mb:1, ml:1 }} />
        <Typography variant="h4" sx={{ textAlign: 'center', mb: 3 }}>
          Démarrer un nouveau rendez-vous ?
        </Typography>

        <List sx={{ backgroundColor: "white", width: "100%", pb: 2, pt: 0, px: 2, borderRadius: 2 }}>
          {friends.map((friend) => (
            <ListItem key={friend._id} sx={{ mt: 2, justifyContent: "space-between", p: 0 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar sx={{ width: 48, height: 48, border: '1px solid #DDD' }} src={friend.avatar || '/avatar/default-avatar.jpg'} alt={friend.firstName} />
                <Box ml={2}>
                  <Typography sx={{ lineHeight: '1em', mb: 1 }} variant="h6" color="textPrimary">
                    {friend.firstName || 'Utilisateur'}
                  </Typography>
                  <Typography sx={{ lineHeight: '1em' }} variant="body2" color="textSecondary">
                    {`@${friend.username}`}
                  </Typography>
                </Box>
              </Box>
              <AddCircleRoundedIcon sx={{ fontSize: "2.4em" }} onClick={() => handleStartAppointment(friend._id)} />
            </ListItem>
          ))}

          {friends.length === 0 && (
            <Typography variant="body1" fontWeight="bold" align="center" sx={{ mt: 2 }}>
              Rendez-vous créé avec succès
            </Typography>
          )}
        </List>



      </Box>
    </Box>
  );
};

export default ManageAppointments;
