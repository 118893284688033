import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'; // Import de l'icône "check"
import { Device } from '@capacitor/device';

const SoloStreak = ({ currentStreak }) => {
  const daysOfWeek = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];

  // Obtenir le jour actuel
  const today = new Date().getDay(); // Renvoie un entier de 0 (dimanche) à 6 (samedi)

  // Fonction pour obtenir les 6 jours précédents dans l'ordre chronologique
  const getLastSixDays = (currentStreak) => {
    let lastDays = [];
    for (let i = 0; i < Math.min(currentStreak, 6); i++) {
      const previousDayIndex = (today - i + 7) % 7; // Calcul pour afficher dans l'ordre chronologique
      lastDays.unshift(daysOfWeek[previousDayIndex]); // Utiliser unshift pour ajouter en début pour l'ordre
    }
    return lastDays;
  };

  // Calcul des jours précédents et du jour suivant
  const lastSixDays = getLastSixDays(currentStreak);
  const nextDay = daysOfWeek[(today + 1) % 7]; // Calculer le jour suivant après le jour actuel

  return (
    <Box sx={{ backgroundColor: 'secondary.main', height: '100vh' }}> 
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="90vh"
        sx={{ px: 3 }}
      >
        <Box component="img" src="/img/foxwatch.svg" alt="Méditation" width="38vw" />

        {/* Affichage du streak */}
        <Typography variant="h2" mt={1}>{currentStreak} jours</Typography>
        <Typography variant="h4">d'affilés</Typography>

        {/* Indicateur de la semaine */}
        <Box 
          display="flex" 
          justifyContent="space-around" 
          alignItems="center" 
          width="100%" 
          mt={3} 
          bgcolor="white" 
          borderRadius={2} 
          p={1}
        >
          {/* Affichage des jours dans l'ordre chronologique */}
          {lastSixDays.map((day, index) => (
            <Box key={index} textAlign="center">
              <Typography variant="body2" color="textSecondary">{day}</Typography>
              <Box
                component="span"
                sx={{
                  display: 'inline-block',
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  bgcolor: '#000000', // Marquer comme complété
                  mt: 1,
                  position: 'relative',
                }}
              >
                <CheckIcon 
                  sx={{
                    color: 'white',
                    fontSize: '18px', // Taille du check
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              </Box>
            </Box>
          ))}

          {/* Affichage du jour suivant (vide) */}
          <Box textAlign="center">
            <Typography variant="body2" color="textSecondary">{nextDay}</Typography>
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                bgcolor: '#E0E0E0', // Marquer comme vide (non complété)
                mt: 1
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SoloStreak;
