import React, { useState, useRef } from 'react';
import { Box, Container, Typography, Button, Switch, FormControlLabel, IconButton, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import AudioPlayerComponent from '../components/AudioPlayerComponent';
import useDeviceInfo from '../utils/useDeviceInfo';

const TimerPage = () => {
  const navigate = useNavigate();
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [showPlayer, setShowPlayer] = useState(false);
  const { paddingTop } = useDeviceInfo();
  const [soundBackground, setSoundBackground] = useState(false);
  const [duration, setDuration] = useState(5); // Durée par défaut
  const svgRef = useRef(null);

  const audioFiles = {
    true: {
      5: 'https://data.heyraoul.fr/audios/timers/free_soundtrue_5.aac',
      10: 'https://data.heyraoul.fr/audios/timers/free_soundtrue_10.aac',
      15: 'https://data.heyraoul.fr/audios/timers/free_soundtrue_15.aac',
      20: 'https://data.heyraoul.fr/audios/timers/free_soundtrue_20.aac',
      25: 'https://data.heyraoul.fr/audios/timers/free_soundtrue_25.aac',
      30: 'https://data.heyraoul.fr/audios/timers/free_soundtrue_30.aac',
    },
    false: {
      5: 'https://data.heyraoul.fr/audios/timers/free_soundfalse_5.aac',
      10: 'https://data.heyraoul.fr/audios/timers/free_soundfalse_10.aac',
      15: 'https://data.heyraoul.fr/audios/timers/free_soundfalse_15.aac',
      20: 'https://data.heyraoul.fr/audios/timers/free_soundfalse_20.aac',
      25: 'https://data.heyraoul.fr/audios/timers/free_soundfalse_25.aac',
      30: 'https://data.heyraoul.fr/audios/timers/free_soundfalse_30.aac',
    },
  };

  const handleSelectAudio = () => {
    const audioSrc = audioFiles[soundBackground][duration];
    setSelectedAudio(audioSrc);
    setShowPlayer(true);
  };

  const calculateDuration = (x, y) => {
    const angle = (Math.atan2(y, x) * (180 / Math.PI) + 450) % 360;
    const newDuration = Math.round((angle / 360) * 30 / 5) * 5;
    return Math.max(5, Math.min(30, newDuration));
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const rect = svgRef.current.getBoundingClientRect();
    const x = touch.clientX - rect.left - 100;
    const y = touch.clientY - rect.top - 100;
    setDuration(calculateDuration(x, y));
  };

  const handleMouseMove = (e) => {
    if (e.buttons !== 1) return;
    const rect = svgRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left - 100;
    const y = e.clientY - rect.top - 100;
    setDuration(calculateDuration(x, y));
  };

  const angle = (duration / 30) * 360;
  const radians = (angle - 90) * (Math.PI / 180);
  const handleX = 100 + 80 * Math.cos(radians);
  const handleY = 100 + 80 * Math.sin(radians);

  return (
    <Box sx={{ height: '95vh', display: 'flex', flexDirection: 'column' }}>
      {/* Toolbar avec bouton de retour */}
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{ p: 0.5, border: '1px solid #ddd', borderRadius: '50%', mr: 2 }}
            aria-label="retour"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Contenu principal */}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        <Container sx={{ textAlign: 'center', px: 3 }}>
          <Typography sx={{ mb: 1 }} variant="h4">
            Chronomètre
          </Typography>



          {/* Sélecteur de durée circulaire */}
          <Box sx={{ position: 'relative', width: 200, height: 200, margin: 'auto', touchAction: 'none' }}>
            <svg
              ref={svgRef}
              width="200"
              height="200"
              viewBox="0 0 200 200"
              onTouchMove={handleTouchMove}
              onMouseMove={handleMouseMove}
            >
              <circle cx="100" cy="100" r="80" stroke="#ddd" strokeWidth="10" fill="none" />
              <circle cx="100" cy="100" r="80" stroke="#000000" strokeWidth="10" fill="none" strokeDasharray="502.4" strokeDashoffset={(502.4 * (30 - duration)) / 30} transform="rotate(-90 100 100)" />
              <circle cx={handleX} cy={handleY} r="12" fill="#" stroke="#fff" strokeWidth="3" />
            </svg>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#000000' }}>
                {duration} min
              </Typography>
            </Box>
          </Box>

          {/* Sélecteur d'ambiance sonore */}
          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              control={<Switch checked={soundBackground} onChange={() => setSoundBackground(!soundBackground)} color="primary" />}
              sx={{ marginRight: 0 }}
              label={soundBackground ? 'Avec ambiance' : 'Sans ambiance'}
            />
          </Box>

          {/* Bouton pour démarrer la méditation */}
          <Button variant="contained" onClick={handleSelectAudio} sx={{ width: '50%', mt: 2 }}>
            Commencer
          </Button>
        </Container>

        {/* Lecteur audio */}
        {showPlayer && selectedAudio && (
          <AudioPlayerComponent
            audioSrc={selectedAudio}
            title={`Méditation de ${duration} minutes`}
            contentType="meditation"
            onClose={() => setShowPlayer(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default TimerPage;
